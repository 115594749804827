import React, { useEffect, useState } from "react"
import Banner from "./stories/Banner.jsx";
import { client } from "./contentfulClient";


const UsingFetch = ({ user, segments }) => {
  const [variant, setVariant] = useState('danger')
  const [content, setContent] = useState([])

  const fetchData = () => {
    // let sgs = segments.toString();
    // fetch(`https://cdn.contentful.com/spaces/vl25w17wkeru/environments/master/entries?content_type=banner&access_token=fMZeOO23fA74gYwUnPyX83l7N_g-YwZz6nX_gSbDVf4&metadata.tags.sys.id[all]=${sgs}`)
    //   .then(async (response) => {
    //     const resp = await response.json()
    //     return resp
    //   })
    //   .then(items => {
    //     if (items && items.items.length > 0) {
    //       return items.items[items.items.length - 1].fields
    //     }
    //   })
    //   .then(data => {
    //     setContent(data.content);
    //     setVariant(data.variant);
    //   })
    return client.getEntries({
      content_type: 'banner',
      'metadata.tags.sys.id[all]': segments.toString()
    }).then(function (entries) {
      if (entries && entries.items.length > 0) {
        return entries.items[entries.items.length - 1].fields;
      }
    }).then(data => {
      console.log('data...');
      console.log(data);
      setContent(data.content);
      setVariant(data.variant);
    });
  }

  useEffect(() => {
    console.log('useEffect...');
    fetchData()
  }, [user, segments])

  return (
    <Banner variant={variant}>
      <p>{content}</p>
    </Banner>
  )
}

export default UsingFetch
