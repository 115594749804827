import React, { useEffect, useState } from "react"
import { ReactComponent as AddToWalletBtn } from './Add_to_Apple_Wallet.svg'

const UsingFetch = (props) => {
  // const [qrcode, setQrcode] = useState('')
  //
  // const fetchData = () => {
  //   fetch(`https://passapi.andrew.ooo/api/qrcode/new-base64/000000001`)
  //     .then(async (response) => {
  //       const resp = await response.text()
  //       setQrcode(resp);
  //     });
  // }
  //
  // useEffect(() => {
  //   console.log('useEffect...');
  //   fetchData()
  // }, [])

  const isLoyaltyCase = (props.case === 'loyalty');
  const url = (isLoyaltyCase) ?
    'https://passapi.andrew.ooo/api/qrcode/new-base64/loyalty'
    : 'https://passapi.andrew.ooo/api/qrcode/new-base64/ticket/000000001'

  return (
    <a href={url}>
      <AddToWalletBtn/>
    </a>
  )
}

export default UsingFetch
