import Qrcode from "./Qrcode.jsx";
import AddToWalletBtn from "./AddToWalletBtn";
import React, { useEffect, useState } from "react"
import {isMobile} from 'react-device-detect';
import {Button} from "./stories/Button";

const UsingFetch = (props) => {

  const updateDataRequest = async (result) => {
    let lastPassSerialNumber = '';
    props.passes.forEach(p => {
      if (p && p.passType === 'pass.ooo.andrew.pass') {
        lastPassSerialNumber = p.serialNumber;
      }
    })
    console.log('lastPassSerialNumber: ', lastPassSerialNumber)
    await fetch(`https://passapi.andrew.ooo/api/passes/request-update/${lastPassSerialNumber}/${result}`)
  }
  const onPrimaryBtnClick = async () => {
    await updateDataRequest("loyalty");
  }

  useEffect(() => {
  }, [])
  return (
    <article>
      <section>
      {(isMobile) ?
        <AddToWalletBtn case='loyalty'/> :
      <Qrcode case='loyalty'/>}
      </section>
      <h2>Actions with Pass</h2>
      {props.passes == null || props.passes.length === 0 ?
        <section className="text-small">
          Please add pass to your wallet first by scanning this QR Code..
        </section>
        :
        <section>
          <Button primary size="small" onClick={onPrimaryBtnClick} label="Add me some Loyalty Points" />
        </section>}
    </article>
  )
}

export default UsingFetch
