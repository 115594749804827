import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

import { Button } from './Button';
import './header.css';

export const Header = ({ user, segments, onLogin, onLogout, onPrimaryBtnClick, onSecondaryBtnClick, title }) => (
  <header>
    <div className="wrapper">
      <div>
        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path
              d="M10 0h12a10 10 0 0110 10v12a10 10 0 01-10 10H10A10 10 0 010 22V10A10 10 0 0110 0z"
              fill="#FFF"
            />
            <path
              d="M5.3 10.6l10.4 6v11.1l-10.4-6v-11zm11.4-6.2l9.7 5.5-9.7 5.6V4.4z"
              fill="#555AB9"
            />
            <path
              d="M27.2 10.6v11.2l-10.5 6V16.5l10.5-6zM15.7 4.4v11L6 10l9.7-5.5z"
              fill="#91BAF8"
            />
          </g>
        </svg>
        <h1>{title}</h1>
      </div>
      <div>
        <div className="dropdown">
          <a className="dropbtn"><Button size="small" label="Use cases" />
            <i className="fa-solid fa-caret-down"></i>
          </a>
          <div className="dropdown-content">
            <Link to="/"><a>Bet Ticket</a></Link>
            <Link to="/loyalty"><a>Loyalty Card</a></Link>
          </div>
        </div>
        {/*{user ? (*/}
        {/*  <>*/}
        {/*    /!*<span className="welcome">*!/*/}
        {/*    /!*  Welcome, <b>{user.name}</b>!*!/*/}
        {/*    /!*</span>*!/*/}
        {/*    /!*  <Button primary size="small" onClick={onPrimaryBtnClick} label="Update Pass (Win)" />*!/*/}
        {/*    /!*  <Button danger size="small" onClick={onSecondaryBtnClick} label="Update Pass (Lost)" />*!/*/}
        {/*      <Button size="small" onClick={onLogout} label="Log out" />*/}
        {/*  </>*/}
        {/*) : (*/}
        {/*  <>*/}
        {/*    <Button size="small" onClick={onLogin} label="Log in" />*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
    </div>
  </header>
);

Header.propTypes = {
  user: PropTypes.shape({}),
  segments: PropTypes.array,
  onPrimaryBtnClick: PropTypes.func,
  onSecondaryBtnClick: PropTypes.func,
  onLogin: PropTypes.func,
  onLogout: PropTypes.func,
  title: PropTypes.string
};

Header.defaultProps = {
  user: null,
  title: 'Pass Demo App'
};

export default Header;
