import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';



// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
//
// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCNIZZP8Vo7mb9Pzb14XlfbQm1XxITPxpI",
//   authDomain: "pass-18e70.firebaseapp.com",
//   projectId: "pass-18e70",
//   storageBucket: "pass-18e70.appspot.com",
//   messagingSenderId: "134561945420",
//   appId: "1:134561945420:web:fb534e6b6b08b2c85b21e9"
// };
//
// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
