import React, { useEffect, useState} from "react"

const UsingFetch = (props) => {
  const [qrcode, setQrcode] = useState('')

  const fetchData = () => {

    const isLoyaltyCase = (props.case === 'loyalty');
    const url = (isLoyaltyCase) ?
      'https://passapi.andrew.ooo/api/qrcode/new-base64/loyalty'
      : 'https://passapi.andrew.ooo/api/qrcode/new-base64/ticket/000000001'
    fetch(url)
      .then(async (response) => {
        const resp = await response.text()
        setQrcode(resp);
      });
  }

  useEffect(() => {
    console.log('useEffect...');
    fetchData()
  }, [])

  return (
    <img src={`data:image/png;base64, ${qrcode}`}/>
  )
}

export default UsingFetch
