import Qrcode from "./Qrcode.jsx";
import AddToWalletBtn from "./AddToWalletBtn";
import React, { useEffect, useState } from "react"
import {isMobile} from 'react-device-detect';
import Banner from "./Banner";
import {Button} from "./stories/Button";

const UsingFetch = (props) => {

  const updateDataRequest = async (result) => {
    let lastPassSerialNumber = '';
    props.passes.forEach(p => {
      if (p && p.passType === 'pass.ooo.andrew.pass') {
        lastPassSerialNumber = p.serialNumber;
      }
    })
    console.log('lastPassSerialNumber: ', lastPassSerialNumber)
    await fetch(`https://passapi.andrew.ooo/api/passes/request-update/${lastPassSerialNumber}/${result}`)
  }
  const onPrimaryBtnClick = async () => {
    await updateDataRequest("winner");
  }
  const onSecondaryBtnClick = async () => {
    await updateDataRequest("lost");
  }

  useEffect(() => {
  }, [])
  return (
    <article>
      <section>
      <Banner user={props.user} segments={props.segments}/>
      {(isMobile) ?
        <AddToWalletBtn/> :
      <Qrcode case={'bet'}/>}
      </section>
      <h2>Actions with Pass</h2>
      {props.passes == null || props.passes.length === 0 ?
        <section className="text-small">
          Please add pass to your wallet first by scanning this QR Code..
        </section>
        :
      <section>
        <Button primary size="small" onClick={onPrimaryBtnClick} label="Update Pass (Win)" />
        <Button danger size="small" onClick={onSecondaryBtnClick} label="Update Pass (Lost)" />
      </section>}
    </article>
  )
}

export default UsingFetch
