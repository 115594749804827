import Header from "./stories/Header.jsx";
import React, { useEffect, useState } from "react"
import { Routes ,Route } from 'react-router-dom';
import BetMain from "./BetMain";
import LoyaltyMain from "./LoyaltyMain";

const UsingFetch = () => {
  const [passes, setPasses] = useState([])
  const [user, setUser] = useState()
  const [segments, setSegments] = useState(['public'])

  const login = async () => {
    console.log('login..')
    const sgs = ['personalizedAndrew']
    setUser({name: 'Andrew'})
    setSegments(sgs)
  }

  const logout = () => {
    setUser(null)
    setSegments(['public'])
  }

  const getPasses = async () => {
    const rawResponse = await fetch('https://passapi.andrew.ooo/api/registered-passes/customer/777');
    const content = await rawResponse.json();
    setPasses(content);

    console.log('User (777) passes: ', content);
  };

  useEffect(async () => {
    setUser(null)
    await getPasses();
  }, [])
  return (
    <div className="App">
      <Header onLogin={login} onLogout={logout} user={user} segments={segments}/>
      <Routes>
        <Route path="/" element={<BetMain user={user} segments={segments} passes={passes}/>}/>
        <Route path="/loyalty" element={<LoyaltyMain passes={passes}/>}/>
      </Routes>
    </div>
  )
}

export default UsingFetch
